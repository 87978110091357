import { useContext, useMemo, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom"

// Images
import testImg from './TestBase64Img';

// Icons
import { RxCross1 } from "react-icons/rx";

// Context
import { RefContext } from "../App";


const LocationClass = ({_class}) =>{
    const [backActive, setBackActive] = useState(false); 

    return(
        <li onTouchEnd={() => setBackActive(false)} onMouseLeave={() => setBackActive(false)} className="h-80 lg:h-96 w-5/6 lg:w-1/4 rounded-lg col-flex-center justify-between  overflow-hidden shadow-gray-400 shadow-md bg-light-turqoise">
            {!backActive ?
                <>
                <img className="w-full h-2/3 object-cover" alt="Class image" src={`${_class?.image64 ? _class.image64 : testImg}`} />
                <div className="col-flex-center justify-start w-full my-1 flex-grow">
                    <div className="flex justify-between items-center w-full">
                        <span className="font-bold text-lg lg:text-3xl text-black rounded p-2">{_class?.title}</span>
                        <span className="font-semibold text-2xl text-green-900 rounded p-2 font-sans">${_class?.fee}</span>
                    </div>
                    <div className="flex justify-between w-full p-2 items-center">
                        <div className="text-lg font-sans font-semibold">{_class?.subtitle}</div>
                        <button onClick={() => setBackActive(true)} className="btn btn-success">More</button>
                    </div>
                </div></>
                :
                <div className="col-flex-center justify-between w-full my-1 flex-grow p-2">
                    <div className="flex flex-col w-full flex-start border-b-2 border-turqoise pb-2">
                        <div className="flex justify-between w-full">
                            <div className="text-2xl">{_class?.month}</div>
                            <div className="flex items-center gap-1">
                                <div className="text-2xl">{_class?.times}</div>
                                <button onClick={() => setBackActive(false)} className="bg-white nice-trans hover:text-blue-500 rounded border p-2 text-lg border-gray-50"><RxCross1 /></button>
                            </div>
                        </div>
                        <div>{_class?.days}</div>
                    </div>
                    <div className="w-full p-2 items-center text-2xl font-sans">
                        {_class?.description}
                    </div>
                </div>
            }
        </li>
    );
}

const SingleLocationPage = ({locations}) => {
    const navigate = useNavigate();
    const { index } = useParams();

    const { locationClasses } = useContext(RefContext)


    // Memoized values
    const location = useMemo(() => {
        if (!locations || !index) return null;
        return locations[index];
    } ,[locations, index]);

    const classes = useMemo(() =>(
        locationClasses?.filter((locationClass) => locationClass.location_ID === location.location_ID)
    ), [location, locationClasses]);


    return (
        <main className="size-full min-h-screen flex-grow col-flex-center justify-between pt-24">
            <h2 className="font-bold text-4xl">Classes</h2>
            <h3 className="font-bold text-lg text-center">{location?.address}</h3>
            <ul className="size-full flex justify-center items-center flex-grow flex-wrap p-0 gap-1 lg:gap-4 font-Poetson">{
            classes?.map((_class) => <LocationClass navigate={navigate} key={`${_class.class_ID} ${_class.location_ID}`} _class={{..._class, ...location}}/>)
            }</ul>
        </main>
    );
}

export default SingleLocationPage